<template>
    <div>

        <div class="row mg-b-50">
            <div v-if="showReference" class="col-12 tx-center tx-bold tx-white tx-uppercase">
                <h1>claim submitted successfully</h1>
                <h1>reference: {{reference}}</h1>
                <h3 class="tx-danger" v-if="vehicleFailed">We were unable to save the vehicle image submitted. Please visit the evidence manager to upload the vehicle image.</h3>
                <h3 class="tx-danger" v-if="receiptFailed">We were unable to save the receipt image submitted. Please visit the evidence manager to upload the receipt image.</h3>
            </div>
            <div v-else class="col-12">

                <h4 class="tx-bold tx-white mg-b-20">Drive Off Form <button class="btn btn-light pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12 mg-l-20 float-right" @click="$emit('mode', 'nmop')">Switch to No Means of Payment</button></h4>

                <div class="form-layout form-layout-6">
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Site: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <select v-model="claim.site_id" class="form-control form-control-dark select2" v-select2>
                                <option v-for="s in sites" v-bind:value="s.id">{{$store.state.auth.demo ? 'Site' : s.name}}</option>
                            </select>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Vehicle Registration: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="text" name="registration" v-model="claim.plate" placeholder="Enter the vehicle registration number">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-12 justify-content-end" style="border-left: none; border-right: none; background-color: transparent; padding-left: 0px; padding-right: 0px;">
                            <button v-if="!searchingMMC" class="btn btn-primary pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12" @click="fetchMMC">Find Make, Model and Colour of Vehicle</button>
                            <button v-else class="btn btn-primary pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12 disabled">
                                <div class="sk-wave" style="height: 12px; margin-top: 0px; margin-bottom: 0px;">
                                    <div class="sk-rect sk-rect1 bg-white"></div>
                                    <div class="sk-rect sk-rect2 bg-white"></div>
                                    <div class="sk-rect sk-rect3 bg-white"></div>
                                    <div class="sk-rect sk-rect4 bg-white"></div>
                                    <div class="sk-rect sk-rect5 bg-white"></div>
                                </div>
                            </button>
                        </div><!-- col-4 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Vehicle Make: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="text" name="make" v-model="claim.make" placeholder="Enter the vehicle make">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Vehicle Model: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="text" name="make" v-model="claim.model" placeholder="Enter the vehicle model">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Vehicle Colour: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="text" name="make" v-model="claim.colour" placeholder="Enter the vehicle colour">
                        </div><!-- col-8 -->
                    </div><!-- row -->

                    <div class="row no-gutters mg-t-20">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Date of Drive Off: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <input type="date" class="form-control form-control-dark tx-14" v-model="claim.date" placeholder="DD/MM/YYYY">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Time of Drive Off: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input type="text" name="time" v-mask="{regex: '^(?:[01]\\d|2[0123]):(?:[012345]\\d):(?:[012345]\\d)$'}" v-model="claim.time" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div><!-- col-8 -->
                    </div><!-- row -->

                    <div class="row no-gutters mg-t-20">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Fuel Type: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <select v-model="claim.type" class="form-control form-control-dark select2" v-select2>
                                <option v-for="t in types" v-bind:value="t">{{t}}</option>
                            </select>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Fuel Value: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" step="0.01" min="0.01" max="10000.00" type="number" name="make" v-model="claim.value" placeholder="Enter the fuel value">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Fuel Litres: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="number" step="0.01" min="0.01" max="1000.00" name="make" v-model="claim.litres" placeholder="Enter the amount of fuel dispensed (litres)">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Pump Number: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="number" step="1" min="1" max="20" name="pump" v-model="claim.pump" placeholder="Enter the pump the fuel was dispensed from">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-12 justify-content-end" style="border-left: none; border-right: none; background-color: transparent; padding-left: 0px; padding-right: 0px; border-bottom: none;">
                            <button v-if="!claim.second_fuel" class="btn btn-primary pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12" @click="claim.second_fuel = true">Add a Second Fuel Type</button>
                            <button v-if="claim.second_fuel" class="btn btn-danger pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12" @click="claim.second_fuel = false">Remove Second Fuel Type</button>
                        </div><!-- col-4 -->
                    </div><!-- row -->
                    <div v-show="claim.second_fuel" class="row no-gutters">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Fuel Type: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <select v-model="claim.fuel_two" class="form-control form-control-dark select2" v-select2>
                                <option v-for="t in types" v-bind:value="t">{{t}}</option>
                            </select>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div v-show="claim.second_fuel" class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Fuel Value: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" step="0.01" min="0.01" max="10000.00" type="number" name="make" v-model="claim.value_two" placeholder="Enter the fuel value">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div v-show="claim.second_fuel" class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Fuel Litres: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="number" step="0.01" min="0.01" max="1000.00" name="make" v-model="claim.litres_two" placeholder="Enter the amount of fuel dispensed (litres)">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div v-show="claim.second_fuel" class="row no-gutters mg-b-20">
                        <div class="col-5 col-sm-4">
                            Pump Number: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <input class="form-control" type="number" step="1" min="1" max="20" name="pump" v-model="claim.pump_two" placeholder="Enter the pump the fuel was dispensed from">
                        </div><!-- col-8 -->
                    </div><!-- row -->


                    <div class="row no-gutters mg-t-20">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Till Receipt: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <p class="tx-danger mg-b-10" v-if="images.receiptErrorMsg.length > 0">{{images.receiptErrorMsg}}</p>
                            <div class="custom-file">
                                <input type="file" id="till_image" accept="image/png,image/jpeg,application/pdf" @change="selectReceiptFile" class="custom-file-input">
                                <label class="custom-file-label">{{receipt}}</label>
                            </div>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters" v-if="images.receiptDataURL !== null">
                        <div class="col-5 col-sm-4">
                            Receipt Preview:
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <button class="btn btn-danger position-absolute" style="top: 25px; right: 25px" v-if="images.receipt !== null" @click="clearReceipt">Clear</button>
                            <pdf id="vars-receipt-image" v-if="images.receiptDataURL !== null && receipt_filetype === 'pdf'" :src="receipt_array"></pdf>
                            <img v-if="images.receiptDataURL !== null && (receipt_filetype === 'png' || receipt_filetype === 'jpeg' || receipt_filetype === 'jpg')" style="width: 100%; height: auto;" :src="images.receiptDataURL">
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters">
                        <div class="col-5 col-sm-4">
                            Vehicle Image: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <p class="tx-danger mg-b-10" v-if="images.vehicleErrorMsg.length > 0">{{images.vehicleErrorMsg}}</p>
                            <div class="custom-file">
                                <input type="file" id="vehicle_image" accept="image/png,image/jpeg,application/pdf" @change="selectVehicleFile" class="custom-file-input">
                                <label class="custom-file-label">{{vehicle}}</label>
                            </div>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                    <div class="row no-gutters" v-if="images.vehicleDataURL !== null">
                        <div class="col-5 col-sm-4">
                            Vehicle Preview:
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8">
                            <p class="tx-danger tx-bold" v-if="vehicle_filetype === 'pdf'">Please be advised that only the 1st page of the PDF will be kept!</p>
                            <button class="btn btn-danger position-absolute" style="top: 25px; right: 25px" v-if="images.vehicle !== null" @click="clearVehicle">Clear</button>
                            <pdf id="vars-vehicle-image" v-if="images.vehicleDataURL !== null && vehicle_filetype === 'pdf'" :src="vehicle_array"></pdf>
                            <img v-if="images.vehicleDataURL !== null && (vehicle_filetype === 'png' || vehicle_filetype === 'jpeg' || vehicle_filetype === 'jpg')" style="width: 100%; height: auto;" :src="images.vehicleDataURL">
                        </div><!-- col-8 -->
                    </div><!-- row -->

                    <div class="card mg-t-20" style="border-left: 5px solid #0866C6;">
                      <div class="card-body">
                        <p style="margin-bottom: 0;"><strong>IMPORTANT:</strong> Any additional information which may be important when chasing the stolen fuel should be entered into the comments field below. For example, if the driver filled a cannister please mention that below.</p>
                      </div>
                    </div>

                    <div class="row no-gutters mg-t-20">
                        <div class="col-5 col-sm-4" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            Comments: <span class="tx-danger mg-l-5">*</span>
                        </div><!-- col-4 -->
                        <div class="col-7 col-sm-8" style="border-top: 1px solid rgba(255, 255, 255, 0.1);">
                            <textarea class="form-control" name="comments" v-model="claim.comments" placeholder="Please provide any further details which may be useful when processing the claim..." rows="5"></textarea>
                        </div><!-- col-8 -->
                    </div><!-- row -->
                </div>

                <div class="row mg-t-20">
                    <div class="col-12">
                        <p class="text-white" v-if="submitting">{{submitStatus}}</p>
                        <p class="tx-bold tx-danger" v-if="error">Please fix the following issues before attempting to submit: </p>
                        <ul class="tx-danger" v-if="error">
                            <li v-for="e in errors">{{e}}</li>
                        </ul>
                        <div class="progress mg-b-20" v-if="submitting">
                            <div class="progress-bar" v-bind:style="{width: images.vehicleProgress.toFixed(0) + '%'}" role="progressbar" v-bind:aria-valuenow="images.vehicleProgress.toFixed(0)" aria-valuemin="0" aria-valuemax="100">{{images.vehicleProgress.toFixed(0)}}%</div>
                        </div>
                        <button v-bind:class="{'disabled': !submissionReady}" v-bind:disabled="!submissionReady" class="btn btn-success pd-x-20 pd-y-10 tx-uppercase tx-bold tx-spacing-6 tx-12" @click="create">Submit Claim</button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import pdf from "vue-pdf";
import Swal from "sweetalert2";
import {DateTime} from "luxon";
import axios from 'axios';
import authHeader from "@/services/auth-header";

export default {
    name: "DriveOffForm",
    components: {pdf},
    data(){
        return {
            claim: {
                plate: "",
                make: "",
                model: "",
                colour: "",
                type: "",
                value: 0.01,
                litres: 0.01,
                pump: 1,
                date: "",
                time: "",
                site_id: -1,
                fuel_two: "",
                litres_two: 0.01,
                value_two: 0.01,
                pump_two: 1,
                second_fuel: false,
                comments: ""
            },
            sites: [],
            submitting: false,
            submitStatus: null,
            error: false,
            errors: [],
            searchingMMC: false,
            types: [
                'Petrol',
                'Diesel',
                'AdBlue',
                'Liquid Petroleum Gas (LPG)',
                'Red Diesel'
            ],
            reference: "",
            showReference: false,
            images: {
                vehicle: null,
                vehicleDataURL: null,
                vehicleProgress: 0,
                vehicleUploading: false,
                vehicleErrorMsg: "",
                receipt: null,
                receiptDataURL: null,
                receiptProgress: 0,
                receiptUploading: false,
                receiptErrorMsg: ""
            },
            vehicleFailed: false,
            receiptFailed: false,
        }
    },
    mounted() {
        $('.fc-datepicker').datepicker({
            dateFormat: "dd/mm/yy"
        });
        axios.get(`https://api.varsanpr.com/api/clients/${this.$store.state.auth.user.selectedClient}/sites`, {
            headers: authHeader()
        })
            .then(response => {
                this.sites = response.data;
                if(this.sites.length > 0){
                    this.claim.site_id = this.sites[0].id;
                }
                $(".select2").select2();
            })
            .catch(error => {
                this.$error("Unable to update site list", error);
            });
    },
    methods: {
        selectVehicleFile(event){
            this.images.vehicleErrorMsg = "";
            if(event.target.files[0].size > 26214400){
                this.images.vehicleErrorMsg = "We can only accept images which are less than 25mb in size!";
                return;
            }
            let type = event.target.files[0].name.split('.').pop().toLowerCase();
            let invalidCount = 0;
            ['png', 'jpg', 'pdf', 'jpeg'].forEach((t) => {
                if(type.indexOf(t) === -1){
                    invalidCount++;
                }
            });
            if(invalidCount >= 4){
                this.images.vehicleErrorMsg = "You have attempted to upload an invalid file type. Please provide one of PNG, JPEG or PDF.";
                return;
            }
            this.images.vehicle = event.target.files[0];
        },
        selectReceiptFile(event){
            this.images.receiptErrorMsg = "";
            if(event.target.files[0].size > 26214400){
                this.images.receiptErrorMsg = "We can only accept images which are less than 25mb in size!";
                return;
            }
            let type = event.target.files[0].name.split('.').pop().toLowerCase();
            let invalidCount = 0;
            ['png', 'jpg', 'pdf', 'jpeg'].forEach((t) => {
                if(type.indexOf(t) === -1){
                    invalidCount++;
                }
            });
            if(invalidCount >= 4){
                this.images.receiptErrorMsg = "You have attempted to upload an invalid file type. Please provide one of PNG, JPEG or PDF.";
                return;
            }
            this.images.receipt = event.target.files[0];
        },
        clearVehicle(){
            this.images.vehicle = null;
            this.images.vehicleDataURL = null;
        },
        clearReceipt(){
            this.images.receipt = null;
            this.images.receiptDataURL = null;
        },
        getReceiptImage(){
            let canvasContainer = document.getElementById('vars-receipt-image');
            let canvas = canvasContainer.getElementsByTagName('CANVAS')[0];
            return this.dataURLtoBlob(canvas.toDataURL('image/png'));
        },
        getVehicleImage(){
            let canvasContainer = document.getElementById('vars-vehicle-image');
            let canvas = canvasContainer.getElementsByTagName('CANVAS')[0];
            return this.dataURLtoBlob(canvas.toDataURL('image/png'));
        },
        create(){
            this.submitting = true;
            this.errors = [];
            this.error = false;
            this.submitStatus = "Submitting claim, please wait.";
            this.claim.plate = this.claim.plate.replace(/\s/g, '').toUpperCase();
            let data = new FormData();
            if(this.vehicle_filetype === 'pdf'){
                data.append('vehicle_image', this.getVehicleImage(), 'vehicle.png');
            }else if(this.images.vehicleDataURL !== null){
                data.append('vehicle_image', this.images.vehicle, this.images.vehicle.name);
            }
            if(this.receipt_filetype === 'pdf'){
                data.append('till_receipt', this.getReceiptImage(), 'receipt.png');
            }else if(this.images.receiptDataURL !== null){
                data.append('till_receipt', this.images.receipt, this.images.receipt.name);
            }
            data.append('registration', this.claim.plate);
            // data.append('make', this.claim.make);
            // data.append('model', this.claim.model);
            // data.append('colour', this.claim.colour);
            let fuels = [];
            fuels.push({
              type: this.claim.type,
              value: this.claim.value,
              litres: this.claim.litres,
              pump: this.claim.pump
            });
            if(this.claim.fuel_two){
              fuels.push({
                type: this.claim.fuel_two,
                value: this.claim.value_two,
                litres: this.claim.litres_two,
                pump: this.claim.pump_two
              })
            }
            data.append('fuels', JSON.stringify(fuels));
            data.append('date', DateTime.fromFormat(this.claim.date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy'));
            data.append('time', this.claim.time.replace(/_/g, '0'));
            data.append('timestamp', DateTime.fromFormat(this.claim.date + ' ' + this.claim.time.replace(/_/g, '0'), 'yyyy-MM-dd HH:mm:ss').toSeconds());
            data.append('site_id', this.claim.site_id);
            data.append('comments', this.claim.comments);
            axios.put(`https://api.varsanpr.com/api/claims/driveoff`, data, {
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 422;
                },
                onUploadProgress: (event) => {
                    this.images.vehicleProgress = Math.round((event.loaded * 100) / event.total);
                },
                headers: authHeader()
            })
                .then((response) => {

                    if(response.status === 200){
                        this.$success(`Successfully submitted claim. ${response.data.reference}`);
                        this.reference = response.data.reference;
                        this.showReference = true;
                    }

                    if(response.status === 422){
                        for(let key in response.data.errors){
                            response.data.errors[key].forEach(msg => {
                                this.errors.push(msg);
                            })
                        }
                        this.error = true;
                        this.submitting = false;
                    }

                })
            .catch((error) => {
                this.$error("Unable to process the submitted claim.", error);
                this.submitting = false;
            });
        },
        fetchMMC(){
            this.searchingMMC = true;
            axios.get(`https://api.varsanpr.com/api/vehicle/external/dvsa/${this.claim.plate}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.claim.make = response.data.vehicle.make;
                    this.claim.model = response.data.vehicle.model;
                    this.claim.colour = response.data.vehicle.colour;
                    this.searchingMMC = false;
                })
                .catch(error => {
                    this.$error("Unable to find the Make, Model and Colour for this vehicle.", error);
                    this.searchingMMC = false;
                });

            // Also check for existing claims
            axios.get(`https://api.varsanpr.com/api/vehicle/${this.claim.plate.replace(/\\s/g, '').toUpperCase()}?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
                .then(response => {
                    if(response.data.claims.length > 0){

                        Swal.fire({
                            icon: 'warning',
                            title: `We have found ${response.data.claims.length} outstanding claims for this vehicle across ${this.$store.state.auth.selectedClientName}`
                        });

                    }
                })
                .catch(error => {
                    // do nothing
                });
        },
        isBlank(str) {
            return (!str || /^\s*$/.test(str));
        },
        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type:mime});
        }
    },
    computed: {
        submissionReady: function(){
            return this.claim.plate.length > 0 && this.claim.make.length > 0 && this.claim.model.length > 0
            && this.claim.colour.length > 0 && this.claim.date.length > 0 && this.claim.time.length > 0
            && this.claim.type.length > 0 && this.claim.value > 0 && this.claim.litres > 0 && this.claim.pump > 0
                && this.images.receiptDataURL !== null;
        },
        vehicle: function(){
            if(this.images.vehicle == null){
                return "Select File";
            }
            if(this.images.vehicle == null){
                return "File Selected";
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.images.vehicleDataURL = e.target.result;
            };
            reader.readAsDataURL(this.images.vehicle);
            let type = this.images.vehicle.name.split('.').pop().toLowerCase();
            if(type.indexOf('pdf') > -1){
                console.log("PDF uploaded");
            }
            return this.images.vehicle.name;
        },
        receipt: function(){
            if(this.images.receipt == null){
                return "Select File";
            }
            if(this.images.receipt == null){
                return "File Selected";
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.images.receiptDataURL = e.target.result;
            };
            reader.readAsDataURL(this.images.receipt);
            return this.images.receipt.name;
        },
        vehicle_filetype: function(){
            if(this.images.vehicle == null){
                return false;
            }
            let re = /(?:\.([^.]+))?$/;
            return re.exec(this.images.vehicle.name)[1];
        },
        receipt_filetype: function(){
            if(this.images.receipt == null){
                return false;
            }
            let re = /(?:\.([^.]+))?$/;
            return re.exec(this.images.receipt.name)[1];
        },
        vehicle_array: function(){
            let BASE64_MARKER = ';base64,';
            var base64Index = this.images.vehicleDataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = this.images.vehicleDataURL.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        },
        receipt_array: function(){
            let BASE64_MARKER = ';base64,';
            var base64Index = this.images.receiptDataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = this.images.receiptDataURL.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    }
}
</script>

<style scoped>

</style>